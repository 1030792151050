import { htmlDecode } from '@vue-storefront/core/filters/html-decode'
import Composite from '@vue-storefront/core/mixins/composite'

export default {
  name: 'CmsPage',
  mixins: [Composite],
  computed: {
    pageTitle () {
      return this.$store.state.cmsPage.current ? this.$store.state.cmsPage.current.meta_title || this.$store.state.cmsPage.current.title : ''
    },
    pageDescription () {
      return this.$store.state.cmsPage.current ? this.$store.state.cmsPage.current.meta_description : ''
    },
    pageKeywords () {
      return this.$store.state.cmsPage.current ? this.$store.state.cmsPage.current.meta_keywords : ''
    }
  },
  metaInfo () {
    return {
      title: htmlDecode(this.pageTitle || this.$route.meta.title),
      meta: [
        { vmid: 'description', name: 'description', content: htmlDecode(this.pageDescription || this.$route.meta.description) },
        { vmid: 'keywords', name: 'keywords', content: htmlDecode(this.pageKeywords) }
      ]
    }
  }
}
