<template>
  <div
    class="odessa-page"
    id="odessa-page"
  >
    <AStatic
      :content="content"
      ref="odessa"
    />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';

export default {
  name: 'Odessa',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  },
  methods: {
    descriptionHandler (event) {
      if (!event.target.classList.contains('section__content_button')) return

      const sectionElement = event.target.parentElement
      if (!sectionElement) return

      const descriptionElement = sectionElement.querySelector('.section__content_description')
      if (!descriptionElement) return

      const buttonElement = sectionElement.querySelector('.section__content_button')
      if (!buttonElement) return

      if (descriptionElement.classList.contains('hidden')) {
        descriptionElement.classList.remove('hidden')
        buttonElement.classList.add('button-chevron--is-active')
        buttonElement.innerHTML = this.$t('Roll up')
      } else {
        descriptionElement.classList.add('hidden')
        buttonElement.classList.remove('button-chevron--is-active')
        buttonElement.innerHTML = this.$t('More details 2')
      }
    },
    initButtons () {
      const odessaElement = document.querySelector('#odessa-page')
      if (!odessaElement) return

      const descriptionElements = odessaElement.querySelectorAll('.section__content_description')

      const buttonElement = `<div class="section__content_button button-chevron button-chevron--is-active">${this.$t('Roll up')}</div>`

      descriptionElements.forEach(e => {
        if (!e.innerHTML.replace('&nbsp;', '')) {
          e.parentElement.removeChild(e)

          return
        }

        e.insertAdjacentHTML('afterEnd', buttonElement);
      })
    }
  },
  mounted () {
    const odessaElement = document.querySelector('#odessa-page')
    if (!odessaElement) return

    this.initButtons()

    odessaElement.addEventListener('click', this.descriptionHandler);
  },
  beforeDestroy () {
    const odessaElement = document.querySelector('#odessa-page')
    if (!odessaElement) return

    odessaElement.removeEventListener('click', this.descriptionHandler);
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.odessa-page {
  ::v-deep {
    .a-static {
      .content {
        padding: 0;

        & > .sf-heading {
          display: none;
        }

        .hidden {
          display: none;
        }
      }
    }

    .section {
      margin: 0;

      .section__block--bold {
        font-weight: bold!important;
      }

      .section__block--underline {
        text-decoration: underline!important;
      }

      .section--center {
        max-width: px2rem(790);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: var(--spacer-20) var(--spacer-15);

        @media (min-width: $tablet-min) {
          flex-direction: row;
          padding: var(--spacer-20) 0;
        }

        .section__content {
          display: flex;
          flex-direction: column;
          justify-content: center;

          @media (min-width: $tablet-min) {
            flex-basis: 62%;
            margin-right: var(--spacer-10);
          }

          &_title {
            font-size: var(--font-size-22);
            font-weight: var(--font-bold);
            font-family: var(--font-family-headers);
            text-transform: uppercase;
            line-height: var(--font-size-28);

            @media (min-width: $tablet-min) {
              font-size: var(--font-size-32);
              line-height: normal;
            }
          }

          &_subtitle {
            margin-top: var(--spacer-16);
            text-align: left;
            font-weight: 400;
            font-size: var(--font-size-14);
            line-height: var(--spacer-17);

            @media (min-width: $tablet-min) {
              text-align: left;
              font-weight: 500;
              font-size: var(--font-size-20);
              line-height: var(--spacer-22);
            }
          }

          &_description {
            margin-top: var(--spacer-24);
            text-align: left;
            font-weight: 400;
            font-size: var(--font-size-14);
            line-height: var(--spacer-24);

            @media (min-width: $tablet-min) {
              margin-top: var(--spacer-40);
            }
          }

          &_button {
            margin-top: var(--spacer-16);
            text-align: left;
            font-weight: 400;
            font-size: var(--font-size-15);
            line-height: var(--spacer-17);
            cursor: pointer;
            display: inline-block;
            width: fit-content;
          }

          &_social-links {
            display: block;
            margin-top: var(--spacer-20);
            width: max-content;
            background-color: var(--white);
            padding: var(--spacer-5) var(--spacer-10) 0 var(--spacer-10);
            border-radius: var(--spacer-10);

            a:first-of-type {
              margin-right: var(--spacer-10);
            }
          }
        }

        .button-chevron {
          position: relative;
          padding-right: var(--spacer-15);

          &:after {
            content: '';
            display: inline;
            border: 2px solid var(--black);
            border-left-width: 0;
            border-top-width: 0;
            position: absolute;
            height: 4px;
            width: 4px;
            top: -3px;
            bottom: 0;
            right: 2px;
            transform: rotate(45deg);
            margin: auto 0;
            transition: transform .1s ease;
            cursor: pointer;
          }

          &--is-active {
            &:after {
              transform: rotate(225deg);
              top: 2px;
            }
          }
        }

        .section__content--white {
          color: var(--white);

          .section__content_title,
          .section__content_subtitle {
            color: var(--white);

            a {
              color: var(--white);
            }

            a:hover {
              color: var(--silver);
            }
          }

          .button-chevron:after {
            border-color: var(--white);
          }
        }

        .section__content--black {
          color: var(--black);
        }

        .section__image {
          @media (max-width: $mobile-max) {
            margin: var(--spacer-16) auto 0;
          }

          .images-wrapper--mobile {
            @media (max-width: $mobile-max) {
              margin-left: px2rem(120)
            }

            @media (min-width: $tablet-min) {
              display: none;
            }
          }

          .images-wrapper--desktop {
            @media (max-width: $mobile-max) {
              display: none;
            }
          }
        }
      }
    }

    .section-1 {
      background-color: #CECCFE;
    }
    .section-2 {
      background-color: #627BFF;
    }
    .section-3 {
      background-color: #E9F3AF;
    }
    .section-4 {
      background-color: #9B00D7;
    }
    .section-5 {
      background-color: #C3E5E6;
    }
    .section-6 {
      background-color: #627BFF;
    }
    .section-7 {
      background-color: #FFCCE0;
    }
    .section-8 {
      background-color: #9B00D7;
    }
    .section-9 {
      background-color:#B5E106;
    }
  }
}
</style>
